import moment from "moment";
import axios from "axios";

const HRDC_CLIENT_NO = "303715";
const ACCOUNT_CODE = "500R-04-02";
const TRACKING_DIVISION = "Division";
const TRACKING_DIVISION_OPTION = "CEO OFFICE";
const TRACKING_PRODUCT = "Product";
const TRACKING_PRODUCT_OPTION = "TaxPOD";

export default {
  data: () => ({
    HRDC_CLIENT_NO,
  }),
  methods: {
    getOriginalAttachmentFileExtension(fileObj) {
      return new Promise((resolve) => {
        var fileReader = new FileReader();
        fileReader.readAsArrayBuffer(fileObj);
        fileReader.onloadend = function (e) {
          var arr = new Uint8Array(e.target.result).subarray(0, 4);
          var header = "";
          for (var i = 0; i < arr.length; i++) {
            header += arr[i].toString(16);
          }

          let fileOriginalExtension = null;

          // Check the file signature against known types
          switch (header) {
            case "25504446":
              fileOriginalExtension = "application/pdf";
              break;
            case "89504e47":
              fileOriginalExtension = "image/png";
              break;
            case "ffd8ffe0":
            case "ffd8ffe1":
            case "ffd8ffe2":
            case "ffd8ffe3":
            case "ffd8ffe8":
              fileOriginalExtension = "image/jpeg";
              break;
            default:
              fileOriginalExtension = fileObj.type;
              break;
          }

          resolve(fileOriginalExtension);
        };
      });
    },
    getEnv() {
      let env = "local";
      switch (process.env.VUE_APP_ENV) {
        case "local":
          env = "local";
          break;
        case "dev":
          env = "dev";
          break;
        case "production":
          env = "production";
          break;
      }

      return env;
    },
    getInvoiceStatus() {
      let invoiceStatus = "DRAFT";
      const env = this.getEnv();
      if (env == "production") {
        invoiceStatus = "AUTHORISED";
      }

      return invoiceStatus;
    },
    requestAuthorizeApp() {
      const responseType = "code";
      const clientId = process.env.VUE_APP_XERO_CLIENT_ID;
      const redirectUri = `${process.env.VUE_APP_URL}/en/service/hrdc/callback`;
      const scope =
        "openid offline_access accounting.transactions accounting.contacts accounting.settings accounting.attachments accounting.attachments.read";
      const state = this.$route.query._ausk;

      const getAccessTokenUrl = `
        ${process.env.VUE_APP_XERO_LOGIN_URL}?response_type=${responseType}&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${state}
      `;

      return getAccessTokenUrl;
    },
    parseJwt(token) {
      return JSON.parse(Buffer.from(token.split(".")[1], "base64").toString());
    },
    // Get XERO basic authorization code.
    getXeroBasicAuthorizationCode() {
      const clientId = process.env.VUE_APP_XERO_CLIENT_ID;
      const clientSecret = process.env.VUE_APP_XERO_CLIENT_SECRET;
      const authorization = Buffer.from(clientId + ":" + clientSecret).toString(
        "base64"
      );

      return authorization;
    },
    // Request access token by refresh token.
    async xeroRequestAccessToken(url, bearerToken, refreshToken) {
      return await axios
        .post(
          url,
          {
            url: process.env.VUE_APP_XERO_EXCHANGE_CODE_URL,
            grant_type: "refresh_token",
            client_id: process.env.VUE_APP_XERO_CLIENT_ID,
            refresh_token: refreshToken,
            basic_auth_token: this.getXeroBasicAuthorizationCode(),
          },
          {
            headers: {
              Authorization: `Bearer ${bearerToken}`,
            },
          }
        )
        .then((response) => {
          if (
            !response ||
            response.status !== 200 ||
            !response.data.data.access_token
          ) {
            throw new Error(
              "Unable to generate access token via refresh token"
            );
          }

          return response.data;
        })
        .catch((err) => {
          // console.log(err)
          return err.message;
        });
    },
    async xeroRequestTenantId({ url, headers, postData }) {
      return await axios
        .post(
          url,
          { ...postData },
          {
            headers,
          }
        )
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          console.log(err);
          return err.message;
        });
    },
    async xeroCallApi(item) {
      let trackingCategories = null;
      let divisonTracking = null;
      let divisonTrackingCategory = null;
      let divisonTrackingOption = null;
      let productTracking = null;
      let productTrackingCategory = null;
      let productTrackingOption = null;
      const { authorization, ...data } = item;
      const url = `${this.$service["hrdc"]}/v1/en/console/xero/xero-api`;

      return await axios
        .post(url, data, {
          headers: {
            Authorization: `Bearer ${authorization}`,
          },
          ...(data.config && Object.keys(data.config).length > 0
            ? data.config
            : {}),
        })
        .then((response) => {
          switch (data.api_type) {
            case "get_contacts":
              return response.data.Contacts.find((item) => {
                return item.AccountNumber == HRDC_CLIENT_NO;
              });

            case "get_accounts":
              return response.data.Accounts.find((item) => {
                return item.Code == ACCOUNT_CODE;
              });

            case "get_tracking_categories":
              trackingCategories = response.data.TrackingCategories;

              divisonTrackingCategory = trackingCategories.find(
                (category) => category.Name === TRACKING_DIVISION
              );

              // Division - CEO Office.
              divisonTrackingOption = divisonTrackingCategory.Options.find(
                (option) => option.Name === TRACKING_DIVISION_OPTION
              );

              divisonTracking = {
                Name: divisonTrackingCategory.Name,
                TrackingCategoryID: divisonTrackingCategory.TrackingCategoryID,
                Option: divisonTrackingOption.Name,
              };

              // Product - TaxPOD.
              productTrackingCategory = trackingCategories.find(
                (category) => category.Name === TRACKING_PRODUCT
              );

              productTrackingOption = productTrackingCategory.Options.find(
                (option) => option.Name === TRACKING_PRODUCT_OPTION
              );

              productTracking = {
                Name: productTrackingCategory.Name,
                TrackingCategoryID: productTrackingCategory.TrackingCategoryID,
                Option: productTrackingOption.Name,
              };

              return {
                divisonTracking,
                productTracking,
              };

            default:
              return response;
          }
        });
    },
    calculateLineAmounts(amount, taxRate) {
      const unitAmount = amount / (taxRate / 100 + 1);
      const taxAmount = amount - unitAmount;
      return { unitAmount, taxAmount };
    },
    convertDateFormat(date) {
      if (date) {
        return moment(date, "YYYY-MM-DD HH:mm:ssZ").format("D MMMM YYYY");
      }

      return "-";
    },
    generateHrdcInvoiceDesc(
      programmeName,
      trainingDate,
      companyName,
      grantApprovalNo
    ) {
      return `taxPOD Masterclass\nEvent Name: ${programmeName}\nDate (Day): ${trainingDate}\nCompany Name: ${companyName.toUpperCase()}\nGrant No: ${grantApprovalNo}`;
    },
    showLoadingDialog() {
      this.loadingText = "Loading...";
      this.loadingDialog = true;
    },
    hideLoadingDialog() {
      this.loadingDialog = false;
    },
  },
};
