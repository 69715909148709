<template>
  <v-container fluid>
    <transition name="fade">
      <v-alert
        dense
        border="left"
        type="warning"
        class="text-pre-wrap"
        v-if="alertError.length > 0"
        ><span
          class="d-block"
          v-for="(error, index) in alertError"
          :key="index"
          >{{ error }}</span
        ></v-alert
      >
    </transition>

    <transition name="fade">
      <v-alert
        dense
        border="left"
        type="error"
        class="text-pre-wrap"
        v-if="alertDanger.length > 0"
        ><span
          class="d-block"
          v-for="(error, index) in alertDanger"
          :key="index"
          >{{ error }}</span
        ></v-alert
      >
    </transition>

    <v-dialog v-model="refundEmailApproval2SubmitDialog" max-width="500">
      <v-card>
        <v-toolbar :color="hrdcData.HRDC_BLUE_THEME_COLOR" dark>
          {{ this.model.name.singular }}
        </v-toolbar>

        <div class="pa-4">
          Please confirm that you wish to Send Refund Email
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green lighten-1"
            class="text-light"
            @click="modalRefundEmailApproval2Submit()"
          >
            Send
          </v-btn>

          <v-btn
            color="red lighten-1"
            class="text-light"
            @click="refundEmailApproval2SubmitDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="refundEmailRejectDialog" max-width="500">
      <v-card>
        <v-card-title class="text-h5"> Reject Refund Email</v-card-title>

        <v-card-text>
          Please confirm that you wish to Reject this Refund Email
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green lighten-1"
            class="text-light"
            @click="modalRefundEmailReject()"
          >
            Reject
          </v-btn>

          <v-btn
            color="red lighten-1"
            class="text-light"
            @click="refundEmailRejectDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--BOC:[contents]-->
    <v-card>
      <div>
        <v-toolbar :color="hrdcData.HRDC_BLUE_THEME_COLOR">
          <v-btn icon class="hidden-xs-only text-white" @click="redirectBack()">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>

          <v-toolbar-title v-if="!loadingDialog">
            <div class="d-flex align-center text-white">
              {{ model.add.name }}
            </div>
          </v-toolbar-title>

          <v-spacer></v-spacer>

          <span
            v-if="
              hrdcData.isTaxpodApproval1AdminRoles && !btnDisabled && !editMode
            "
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="error"
                  class="hidden-xs-only text-white mr-5"
                  :loading="apiSubmit.isLoading"
                  @click="refundEmailRejectDialog = true"
                >
                  <v-icon dark v-bind="attrs" v-on="on">
                    mdi-file-remove
                  </v-icon>
                </v-btn>
              </template>
              <span>Reject Refund Email</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="success"
                  class="hidden-xs-only text-white"
                  :loading="apiSubmit.isLoading"
                  @click="refundEmailApproval2SubmitDialog = true"
                >
                  <v-icon dark v-bind="attrs" v-on="on">
                    mdi-file-check
                  </v-icon>
                </v-btn>
              </template>
              <span>Approve Refund Email</span>
            </v-tooltip>
          </span>
        </v-toolbar>
      </div>

      <div>
        <v-col class="d-flex align-center" cols="12" sm="12" md="6">
          <div>Refund Email:</div>
          <v-dialog transition="dialog-bottom-transition" max-width="1500">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                outlined
                :color="hrdcData.HRDC_BLUE_THEME_COLOR"
                v-bind="attrs"
                v-on="on"
                @click="getEmailContent()"
                class="ml-2"
                >View</v-btn
              >
            </template>
            <template v-slot:default="dialog">
              <v-card>
                <v-toolbar :color="hrdcData.HRDC_BLUE_THEME_COLOR" dark>{{
                  emailModalDetails.emailModalHeader
                }}</v-toolbar>
                <v-card-text>
                  <div class="pa-6">
                    <v-row no-gutters>
                      <v-alert text>
                        <span class="h5">
                          Subject:
                          {{ emailModalDetails.emailModalSubject }}
                        </span>
                      </v-alert>
                    </v-row>
                    <v-alert text>
                      <v-row no-gutters>
                        <div>
                          <div class="d-inline">
                            <span class="h6">To Recipient:</span>
                          </div>
                          <div class="d-inline">
                            <span
                              v-for="(
                                toRecipient, index
                              ) in emailModalDetails.emailModalToRecipient"
                              :key="index"
                              class="font-weight-bold"
                            >
                              {{ toRecipient }};
                            </span>
                          </div>
                        </div>
                      </v-row>
                      <v-row no-gutters>
                        <div>
                          <div class="d-inline">
                            <span class="h6">Cc Recipient:</span>
                          </div>
                          <div class="d-inline">
                            <span
                              v-for="(
                                ccRecipient, index
                              ) in emailModalDetails.emailModalCcRecipient"
                              :key="index"
                              class="font-weight-bold"
                            >
                              {{ ccRecipient }};
                            </span>
                          </div>
                        </div>
                      </v-row>
                      <hr />
                      <v-row no-gutters>
                        <v-col cols="12">
                          <div
                            class="pa-3"
                            v-html="emailModalDetails.emailModalContent"
                          ></div>
                        </v-col>
                      </v-row>
                    </v-alert>
                  </div>
                </v-card-text>
                <v-card-actions class="justify-end">
                  <v-btn text @click="dialog.value = false">Close</v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
        </v-col>

        <v-row
          class="d-flex justify-space-between"
          cols="12"
          lg="12"
          md="12"
          sm="12"
        >
          <v-col
            cols="6"
            style="border-right: 1px solid lightgrey"
            class="pl-5"
          >
            <v-expansion-panels v-model="panel" focusable elevation="0">
              <v-expansion-panel>
                <v-expansion-panel-header
                  >Refund Details</v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <!-- taxPOD Price -->
                  <v-col class="d-flex px-0" cols="12" sm="12" md="12">
                    <v-text-field
                      dense
                      filled
                      disabled
                      label="taxPOD Price"
                      v-model="formattedPrice"
                      :error-messages="errorField.taxpodPrice"
                    ></v-text-field>
                  </v-col>

                  <!-- Subscription Start & End Date -->
                  <v-row class="pa-3 align-items-center justify-space-between">
                    <v-col cols="12" lg="5" sm="5" md="5" class="px-0">
                      <v-menu
                        ref="menuSubscriptionStartDate"
                        v-model="menuSubscriptionStartDate"
                        :close-on-content-click="false"
                        :return-value.sync="date"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            dense
                            filled
                            v-model="formData.subscriptionStartDate"
                            :error-messages="errorField.subscriptionStartDate"
                            label="Subscription Start Date (YYYY-MM-DD)"
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="formData.subscriptionStartDate"
                          no-title
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="menuSubscriptionStartDate = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.menuSubscriptionStartDate.save(date)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    to
                    <v-col cols="12" lg="6" sm="6" md="6">
                      <v-menu
                        ref="menuSubscriptionEndDate"
                        v-model="menuSubscriptionEndDate"
                        :close-on-content-click="false"
                        :return-value.sync="date"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            dense
                            filled
                            v-model="formData.subscriptionEndDate"
                            :error-messages="errorField.subscriptionEndDate"
                            label="Subscription End Date (YYYY-MM-DD)"
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="formData.subscriptionEndDate"
                          no-title
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="menuSubscriptionEndDate = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.menuSubscriptionEndDate.save(date)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>

                  <!-- Email Subject -->
                  <v-col class="d-flex px-0" cols="12" sm="12" md="12">
                    <v-text-field
                      dense
                      filled
                      v-model="formData.emailSubject"
                      :error-messages="errorField.emailSubject"
                      label="Email Subject"
                    ></v-text-field>
                  </v-col>

                  <!--To taxPOD Approval Admin 1 & 2-->
                  <v-col class="d-flex px-0" cols="12" sm="12" md="12">
                    <v-combobox
                      dense
                      filled
                      label="TO Recipient"
                      v-model="formData.toEmail"
                      :error-messages="errorField.toEmail"
                      :hide-selected="true"
                      multiple
                      small-chips
                    >
                      <template v-slot:selection="data">
                        <v-chip
                          small
                          :key="JSON.stringify(data.item)"
                          v-bind="data.attrs"
                          :input-value="data.selected"
                          :disabled="data.disabled"
                          @click:close="data.parent.selectItem(data.item)"
                        >
                          {{ data.item }}
                          <v-icon
                            small
                            @click="data.parent.selectItem(data.item)"
                          >
                            $delete
                          </v-icon>
                        </v-chip>
                      </template>

                      <template v-slot:append>
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on"> mdi-information </v-icon>
                          </template>
                          Press <kbd>enter/tab</kbd> to insert multiple email
                        </v-tooltip>
                      </template></v-combobox
                    >
                  </v-col>

                  <!--CC Related PIC (Top Management)-->
                  <v-col class="d-flex px-0" cols="12" sm="12" md="12">
                    <v-combobox
                      dense
                      filled
                      label="CC Recipient"
                      v-model="formData.ccEmail"
                      :error-messages="errorField.ccEmail"
                      :hide-selected="true"
                      multiple
                      small-chips
                    >
                      <template v-slot:selection="data">
                        <v-chip
                          small
                          :key="JSON.stringify(data.item)"
                          v-bind="data.attrs"
                          :input-value="data.selected"
                          :disabled="data.disabled"
                          @click:close="data.parent.selectItem(data.item)"
                        >
                          {{ data.item }}
                          <v-icon
                            small
                            @click="data.parent.selectItem(data.item)"
                          >
                            $delete
                          </v-icon>
                        </v-chip>
                      </template>

                      <template v-slot:append>
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on"> mdi-information </v-icon>
                          </template>
                          Press <kbd>enter/tab</kbd> to insert multiple email
                        </v-tooltip>
                      </template></v-combobox
                    >
                  </v-col>

                  <!--Screenshot Refund Bank Account Details-->
                  <v-col
                    class="d-flex pb-0 px-0"
                    cols="12"
                    lg="12"
                    sm="12"
                    md="12"
                  >
                    <div class="w-100">
                      <v-file-input
                        :prepend-icon="null"
                        prepend-inner-icon="mdi-paperclip"
                        dense
                        filled
                        small-chips
                        disabled
                        label="Screenshot Refund Bank Account Details"
                        v-model="formData.ssRefundBankAccDetails"
                        :truncate-length="1000"
                      ></v-file-input>
                    </div>
                  </v-col>

                  <!-- Include Screenshot checkbox -->
                  <v-col class="d-flex pa-0" cols="12">
                    <div class="pt-0">
                      <v-checkbox
                        class="pt-0"
                        color="primary"
                        @change="changeAttachSs()"
                        :label="this.computedSsCheckMsg"
                        v-model="formData.ssCheck"
                      ></v-checkbox>
                    </div>
                  </v-col>

                  <!--HRDC Invoice-->
                  <v-row class="pl-3">
                    <v-col class="d-flex pl-0" cols="12" lg="8" sm="8" md="8">
                      <div class="w-100">
                        <v-file-input
                          :prepend-icon="null"
                          prepend-inner-icon="mdi-paperclip"
                          dense
                          filled
                          small-chips
                          label="HRDC Invoice (Auto Insert)"
                          disabled
                          v-model="formData.manualInvoice"
                          :error-messages="errorField.manualInvoice"
                          :truncate-length="1000"
                        ></v-file-input>
                      </div>
                    </v-col>
                    <v-col cols="12" lg="4" sm="4" md="4" class="pl-0 pr-5">
                      <v-text-field
                        dense
                        filled
                        v-model="formData.manualInvoiceToHrdc"
                        :error-messages="errorField.manualInvoiceToHrdc"
                        label="HRDC Invoice No."
                        disabled
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <!--Client/taxPOD Invoice-->
                  <v-row class="pl-3">
                    <v-col class="d-flex pl-0" cols="12" lg="8" sm="8" md="8">
                      <div
                        v-cloak
                        @drop.prevent="
                          addDropMultipleFile(
                            $event,
                            'autoGeneratedTaxpodInvoice',
                            'autoGeneratedTaxpodInvoiceNo',
                            'pdf.*'
                          )
                        "
                        @dragover.prevent
                        class="w-100"
                      >
                        <v-file-input
                          :prepend-icon="null"
                          prepend-inner-icon="mdi-paperclip"
                          dense
                          filled
                          small-chips
                          multiple
                          label="Client/taxPOD Invoice"
                          accept=".pdf"
                          @change="
                            scanPdf(
                              $event,
                              'autoGeneratedTaxpodInvoice',
                              'autoGeneratedTaxpodInvoiceNo'
                            )
                          "
                          v-model="formData.autoGeneratedTaxpodInvoice"
                          :error-messages="
                            errorField.autoGeneratedTaxpodInvoice
                          "
                          :truncate-length="1000"
                          disabled
                        ></v-file-input>
                      </div>
                    </v-col>
                    <v-col cols="12" lg="4" sm="4" md="4" class="pl-0 pr-5">
                      <v-text-field
                        dense
                        filled
                        v-model="formData.autoGeneratedTaxpodInvoiceNo"
                        :error-messages="
                          errorField.autoGeneratedTaxpodInvoiceNo
                        "
                        label="Invoice No."
                        disabled
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <!--Credit Note-->
                  <v-row class="pl-3">
                    <v-col class="d-flex pl-0" cols="12" lg="8" sm="8" md="8">
                      <div
                        v-cloak
                        @drop.prevent="
                          addDropMultipleFile(
                            $event,
                            'creditNote',
                            'creditNoteNo',
                            'pdf.*'
                          )
                        "
                        @dragover.prevent
                        class="w-100"
                      >
                        <v-file-input
                          :prepend-icon="null"
                          prepend-inner-icon="mdi-paperclip"
                          dense
                          filled
                          small-chips
                          multiple
                          label="Credit Note"
                          @change="
                            scanPdf($event, 'creditNote', 'creditNoteNo')
                          "
                          v-model="formData.creditNote"
                          :error-messages="errorField.creditNote"
                          :truncate-length="1000"
                        ></v-file-input>
                      </div>
                    </v-col>
                    <v-col cols="12" lg="4" sm="4" md="4" class="pl-0 pr-5">
                      <v-text-field
                        dense
                        filled
                        v-model="formData.creditNoteNo"
                        :error-messages="errorField.creditNoteNo"
                        label="CN No."
                        disabled
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <!--Payment Advice From HRDC-->
                  <v-col class="d-flex pl-0" cols="12" sm="12" md="12">
                    <div
                      v-cloak
                      @drop.prevent="
                        addDropFile(
                          $event,
                          'paymentAdviceFromHrdc',
                          null,
                          'image/*'
                        )
                      "
                      @dragover.prevent
                      class="w-100"
                    >
                      <v-file-input
                        :prepend-icon="null"
                        prepend-inner-icon="mdi-paperclip"
                        dense
                        filled
                        small-chips
                        @change="
                          checkAttachment($event, 'paymentAdviceFromHrdc')
                        "
                        accept="image/*"
                        label="Payment Advice From HRDC"
                        v-model="formData.paymentAdviceFromHrdc"
                        :error-messages="errorField.paymentAdviceFromHrdc"
                        :truncate-length="1000"
                      ></v-file-input>
                    </div>
                  </v-col>

                  <!--Payment Advice From Client (Stripe/iPay/...)-->
                  <v-col class="d-flex pl-0" cols="12" sm="12" md="12">
                    <div
                      v-cloak
                      @drop.prevent="
                        addDropFile(
                          $event,
                          'paymentAdviceFromClient',
                          null,
                          null
                        )
                      "
                      @dragover.prevent
                      class="w-100"
                    >
                      <v-file-input
                        :prepend-icon="null"
                        prepend-inner-icon="mdi-paperclip"
                        dense
                        filled
                        small-chips
                        @change="
                          checkAttachment($event, 'paymentAdviceFromClient')
                        "
                        label="Payment Advice From Client"
                        v-model="formData.paymentAdviceFromClient"
                        :error-messages="errorField.paymentAdviceFromClient"
                        :truncate-length="1000"
                      ></v-file-input>
                    </div>
                  </v-col>

                  <!--Payment Advice Type-->
                  <v-col class="d-flex pl-0" cols="12" sm="12" md="12">
                    <v-select
                      :items="paymentAdviceItems"
                      v-model="formData.paymentAdviceType"
                      filled
                      dense
                      label="Payment Advice Type"
                    ></v-select>
                  </v-col>

                  <!--Other Attachments-->
                  <v-col class="d-flex pl-0" cols="12" sm="12" md="12">
                    <div
                      v-cloak
                      @drop.prevent="
                        addDropFile(
                          $event,
                          'refundOtherAttachments',
                          null,
                          null
                        )
                      "
                      @dragover.prevent
                      class="w-100"
                    >
                      <v-file-input
                        :prepend-icon="null"
                        prepend-inner-icon="mdi-paperclip"
                        dense
                        filled
                        multiple
                        small-chips
                        @change="
                          checkAttachment($event, 'refundOtherAttachments')
                        "
                        label="Other Attachments (etc: Authorization Letter...)"
                        v-model="formData.refundOtherAttachments"
                        :error-messages="errorField.refundOtherAttachments"
                        :truncate-length="1000"
                        hint="Kindly add other attachments details to email template at below if have any."
                        persistent-hint
                      ></v-file-input>
                    </div>
                  </v-col>

                  <!--Refund Remarks-->
                  <v-col class="d-flex pl-0" cols="12" sm="12" md="12">
                    <v-textarea
                      v-model="formData.refundRemarks"
                      filled
                      dense
                      no-resize
                      label="Refund Remarks"
                    ></v-textarea>
                  </v-col>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>

            <div v-if="imageUrl">
              <hr />
              <v-col class="d-flex" cols="12" sm="12" md="12">
                <iframe
                  :src="imageUrl"
                  alt=""
                  width="100%"
                  height="900"
                ></iframe>
              </v-col>
            </div>
          </v-col>

          <v-col cols="6">
            <span v-for="(attn, index) in refundEmailAttachments" :key="index">
              <v-btn
                @click="previewOrDownloadAttachment(index, 'preview')"
                :color="hrdcData.HRDC_BLUE_THEME_COLOR"
                elevation="2"
                outlined
                class="mr-1 mb-1"
              >
                {{ attn }}
              </v-btn>
            </span>

            <span v-show="Object.keys(otherRefundEmailAttachments).length > 0">
              <v-btn
                v-for="(otherAttn, index) in otherRefundEmailAttachments"
                :key="index"
                :color="hrdcData.HRDC_BLUE_THEME_COLOR"
                class="mr-1 mb-1"
                outlined
                elevation="2"
                @click.prevent="previewOrDownloadAttachment(index, 'preview')"
              >
                {{ otherAttn }}
              </v-btn>
            </span>

            <hr />
            <!-- Email Content -->
            <v-col class="" cols="12" sm="12" md="12">
              <div>
                <span class="text--disabled">Email Content</span>
                <span class="font-weight-bold text-danger"
                  >(*Only edit template after upload all attachments)</span
                >
              </div>
              <div>
                <!-- Use the component in the right place of the template -->
                <tiptap-vuetify
                  v-model="formData.emailContent"
                  :extensions="extensions"
                />
              </div>
              <span class="text-danger">{{
                errorField.emailContent ? errorField.emailContent[0] : null
              }}</span>
            </v-col>
          </v-col>
        </v-row>

        <!-- Refund Email Approval 2 Date -->
        <v-col cols="12" sm="6" md="6" v-show="false">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                filled
                v-model="formData.refundEmailApproval2Date"
                label="Refund Email Approval 2 Date (YYYY-MM-DD)"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                :disabled="true"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="formData.refundEmailApproval2Date"
              no-title
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
              <v-btn text color="primary" @click="$refs.menu.save(date)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
      </div>
    </v-card>

    <HrdcLoader :loadingDialog="loadingDialog"></HrdcLoader>
  </v-container>
</template>

<script>
import Api from "@/objects/api";
import Model from "@/objects/model";
import Service from "@/objects/service";
import HrdcData from "@/services/hrdc/objects/globalData";
import { mapState } from "vuex";
import moment from "moment";
import checkForm from "@/services/hrdc/objects/checkForm";
import hrdcFunction from "@/services/hrdc/objects/hrdcFunction";
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from "tiptap-vuetify";

export default {
  mixins: [checkForm, hrdcFunction],
  components: {
    TiptapVuetify,
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth.data,
    }),
    formattedPrice() {
      return `RM ${parseFloat(this.formData.taxpodPrice).toLocaleString()}`;
    },
    computedSsCheckMsg() {
      if (this.formData.ssCheck) {
        return "Include Screenshot Refund Bank Account Details in Refund Email";
      } else {
        return "Exclude Screenshot Refund Bank Account Details Refund Email";
      }
    },
  },
  props: ["params"],
  data: () => ({
    apiGetApplicationSingleDetail: new Api(),
    apiGetRefundAttachment: new Api(),
    apiScanPdfText: new Api(),
    apiGetRefundEmailRecipientHistory: new Api(),
    apiGetEmailDetails: new Api(),
    apiGetFileAttachment: new Api(),
    apiDownloadOrPreviewAttachment: new Api(),
    apiSubmit: new Api(),
    apiReject: new Api(),
    model: new Model(),
    service: new Service(),
    hrdcData: new HrdcData(),
    alertError: [],
    alertDanger: [],
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    menuSubscriptionStartDate: false,
    menuSubscriptionEndDate: false,
    formData: {
      applicationNo: null,
      taxpodPrice: null,
      refundEmailApproval2Date: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      remarks: null,
      toEmail: [],
      ccEmail: [],
      toEmailUuid: [],
      ccEmailUuid: [],
      emailSubject: null,
      manualInvoiceToHrdc: null,
      manualInvoice: {
        name: "HRDC Invoice",
      },
      ssRefundBankAccDetails: {
        name: "Screenshot of Refund Bank Account Details.png",
      },
      subscriptionStartDate: null,
      subscriptionEndDate: null,
      ssCheck: null,
      autoGeneratedTaxpodInvoice: [],
      autoGeneratedTaxpodInvoiceNo: null,
      paymentAdviceFromHrdc: null,
      paymentAdviceFromClient: null,
      paymentAdviceType: "Stripe",
      emailContent: null,
      creditNote: [],
      creditNoteNo: null,
      ssCheckMsg:
        "Include Screenshot Refund Bank Account Details in Refund Email",
      oldCompanyName: null,
      oldPicName: null,
      oldSsmNo: null,
      bankUuid: null,
      oldBankAccount: null,
      paymentReceivedDate: null,
      refundOtherAttachments: [],
      refundRemarks: null,
      oldBankAccountHolderName: null,
    },
    errorField: {
      applicationNo: null,
      refundEmailApproval2Date: null,
      remarks: null,
      toEmail: null,
      ccEmail: null,
      emailSubject: null,
      manualInvoice: null,
      manualInvoiceToHrdc: null,
      subscriptionStartDate: null,
      subscriptionEndDate: null,
      refundOtherAttachments: null,
    },
    emailModalDetails: {
      emailModalHeader: null,
      emailModalSubject: null,
      emailModalContent: null,
      emailModalToRecipient: null,
      emailModalCcRecipient: null,
    },
    refundEmailAttachments: {
      autoGeneratedInvoice: "Client/taxPOD Invoice",
      creditNoteGenerated: "Credit Note",
      manualInvoiceToHrdc: "HRDC Invoice",
      paymentAdviceFromClient: "Payment Advice From Client",
      paymentAdviceFromHrdc: "Payment Advice From HRDC",
    },
    otherRefundEmailAttachments: {},
    loadingDialog: false,
    refundEmailApproval2SubmitDialog: false,
    refundEmailRejectDialog: false,
    editMode: false,
    btnDisabled: false,
    paymentAdviceItems: null,
    numberSixItemWord:
      "Screenshot of Refund Bank Account Details Provided by Client",
    imageUrl: null,
    panel: [0],
    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3],
          },
        },
      ],
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak,
    ],
  }),
  watch: {
    "formData.autoGeneratedTaxpodInvoiceNo": function () {
      this.updateEmailContent();
    },
    "formData.paymentAdviceType": function () {
      this.updateEmailContent();
    },
    "formData.creditNoteNo": function () {
      this.updateEmailContent();
    },
    "formData.subscriptionStartDate": function () {
      this.updateEmailContent();
    },
    "formData.subscriptionEndDate": function () {
      this.updateEmailContent();
    },
    "formData.ssCheck": function () {
      if (this.formData.ssCheck) {
        this.formData.ssCheckMsg =
          "Include Screenshot Refund Bank Account Details in Refund Email";

        this.numberSixItemWord =
          "Screenshot of Refund Bank Account Details Provided by Client";
      } else {
        this.formData.ssCheckMsg =
          "Exclude Screenshot Refund Bank Account Details Refund Email";

        this.numberSixItemWord = "Authorized Letter";
      }

      this.updateEmailContent();
    },
  },
  async created() {
    this.loadingDialog = !this.loadingDialog;
    this.formData.applicationNo = this.$route.query._ausk;
    this.model.getByKey(this.params.modelKey);
    if (this.model.serviceKey) this.service.set("key", this.model.serviceKey);

    // Assign apiDetails by mapping repository.
    const apiDetails = {
      apiSubmit: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/refund_email_approval_2/create-refund-email-approval-2`,
        method: "post",
      },
      apiGetApplicationSingleDetail: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/application/get-single-application`,
        method: "post",
        params: {
          applicationUuid: this.$route.query._ausk,
        },
      },
      apiGetRefundEmailRecipientHistory: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/refund_email_submission/get-refund-email-recipient-history`,
        method: "post",
      },
      apiGetEmailDetails: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/application/get-email-details`,
        method: "post",
      },
      apiDownloadOrPreviewAttachment: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/application/azure-download-or-preview-application-attachment`,
      },
      apiGetFileAttachment: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/application/get-attachment`,
        method: "post",
      },
      apiGetRefundAttachment: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/application/get-attachment-array`,
        method: "post",
      },
      apiScanPdfText: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/refund_email_submission/scan-pdf`,
        method: "post",
      },
      apiReject: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/application/reject-refund-email`,
        method: "post",
      },
    };

    for (const api in apiDetails) {
      if (apiDetails[api].url !== undefined) {
        this[api].setUrl(apiDetails[api].url);
      }
      if (apiDetails[api].method !== undefined) {
        this[api].setMethod(apiDetails[api].method);
      }
      if (apiDetails[api].params !== undefined) {
        this[api].setParams(apiDetails[api].params);
      }
    }

    this.stages = await this.hrdcData.allStages;
    const currentStageId =
      this.hrdcData.stageIndex[this.$store.state.hrdc.data.stageId];
    this.hrdcData.rolesAssignation(this.auth.roles);
    if (!this.hrdcData.isTaxpodApproval2AdminRoles) {
      this.alertError.push("You are not allowed to perform this action!");
      this.btnDisabled = true;
    }

    this.checkFormAlreadyFilledGte(
      currentStageId,
      this.hrdcData.stageSlug.refundEmailApproval2
    );

    this.checkFormNotYetFilledLesser(
      currentStageId,
      this.hrdcData.stageSlug.refundEmailApproval1
    );

    // Assign payment advice options.
    this.paymentAdviceItems = this.hrdcData.paymentAdviceOpts;

    // Assgin screenshot check.
    if (!this.formData.ssCheck) {
      this.numberSixItemWord = "Authorized Letter";
    }

    const refundStage = [
      this.hrdcData.stagePriority.refundEmailSubmission,
      this.hrdcData.stagePriority.refundEmailApproval1,
      this.hrdcData.stagePriority.refundEmailApproval2,
    ];

    if (this.editMode) {
      this.apiGetApplicationSingleDetail.fetch();
      this.apiGetApplicationSingleDetail.setCallbackCompleted((response) => {
        try {
          const { status, data, company } = response;
          if (status) {
            // Pricing
            this.formData.taxpodPrice = data.totalPrice;

            // Check if pricing is unsual, display warning.
            if (
              !this.hrdcData.staticTaxpodPricing.includes(
                Number(data.totalPrice)
              )
            ) {
              this.alertDanger.push("Detected Unusual Price, Please Take Note");
            }

            const subscription = data.CompanyTaxpodSubscription.find(
              (item) => item.applicationUuid == this.formData.applicationNo
            );

            // Subscription Period.
            this.formData.subscriptionStartDate =
              subscription !== undefined
                ? subscription.subscriptionStartDate
                : null;
            this.formData.subscriptionEndDate =
              subscription !== undefined
                ? subscription.subscriptionEndDate
                : null;

            // Recipient Details.
            let toRecipient, ccRecipient;
            let refundEmail = data.ApplicationEmail.find((item) =>
              refundStage.includes(item.stageId)
            );
            if (refundEmail) {
              toRecipient = refundEmail.ApplicationEmailDetail.filter(
                (item) => item.recipientType == "TO"
              ).map((item2) => item2.recipientEmail);
              ccRecipient = refundEmail.ApplicationEmailDetail.filter(
                (item) => item.recipientType == "CC"
              ).map((item2) => item2.recipientEmail);

              // Email subject.
              this.formData.emailSubject = refundEmail.emailSubject;
            }

            this.formData.toEmail = toRecipient;
            this.formData.ccEmail = ccRecipient;

            // HRDC Invoice No.
            this.formData.manualInvoiceToHrdc = data.manualInvoiceToHrdc;

            // Client/taxPOD Invoice No.
            this.formData.autoGeneratedTaxpodInvoiceNo =
              data.autoGeneratedTaxpodInvoiceNo;

            // CN No.
            this.formData.creditNoteNo = data.creditNoteNo;

            // Payment Advice Type.
            this.formData.paymentAdviceType = data.paymentAdviceFromClientType;

            // Assign company data.
            this.formData.oldCompanyName = this.capitalizeFirstLetter(
              company.oldCompanyName
            );
            this.formData.oldPicName = company.oldPicName;
            this.formData.oldSsmNo = company.oldSsmNo;
            this.formData.bankUuid = company.bankUuid;
            this.formData.oldBankAccount = company.oldBankAccount;
            this.formData.oldBankAccountHolderName =
              company.oldBankAccountHolderName;

            const refundEmailApproval2StageId =
              this.hrdcData.stagePriority.refundEmailApproval2;
            this.formData.refundEmailApproval2Date = moment(
              data.ApplicationLog.find(
                (obj) => obj.stageId == refundEmailApproval2StageId
              ).startDate
            ).format("YYYY-MM-DD");

            // Email Content
            let refundEmailTemplate = data.ApplicationEmail.find((item) =>
              refundStage.includes(item.stageId)
            );

            if (refundEmailTemplate) {
              refundEmailTemplate = refundEmailTemplate.emailContent;
            }

            // Check is drafted refund email template got keyword 'screenshot' or not.
            let ssCheck = false;
            if (refundEmailTemplate.indexOf("Screenshot") !== -1) {
              ssCheck = true;
              this.refundEmailAttachments.ssRefundBankAccDetails =
                "Screenshot Refund Bank Account Details";
            }

            this.formData.ssCheck = ssCheck;

            // Assign refund remarks.
            this.formData.refundRemarks = data.refundRemarks;

            // Draft for attachment.
            const stagePriorities = [
              this.hrdcData.stagePriority.creditNoteGenerated,
              this.hrdcData.stagePriority.autoGeneratedTaxpodInvoice,
              this.hrdcData.stagePriority.paymentAdviceFromHrdc,
              this.hrdcData.stagePriority.paymentAdviceFromClient,
              this.hrdcData.stagePriority.refundEmailOtherAttachments,
            ];

            const attachments = {};
            let otherRefundAttnCount = 0;

            for (const stageId of stagePriorities) {
              let filesArr = [];
              let checkMultipleAtch = [];
              const attachment = data.ApplicationAttachment.filter(
                (item) => item.stageId == stageId
              );

              if (attachment.length > 0) {
                for (const atch of attachment) {
                  checkMultipleAtch.push(atch.stageId);
                  // Cater for other refund attachments.
                  if (
                    stageId ==
                    this.hrdcData.stagePriority.refundEmailOtherAttachments
                  ) {
                    if (!attachments[stageId]) {
                      attachments[stageId] = {};
                    }

                    attachments[stageId][otherRefundAttnCount] = {
                      fileUrl: atch.fileUrl,
                      fileName: atch.oriFileName,
                    };
                    otherRefundAttnCount++;
                  } else {
                    filesArr.push(atch.fileUrl);

                    if (checkMultipleAtch.length > 1) {
                      // Get attachment display name based on stageId.
                      switch (atch.stageId) {
                        case this.hrdcData.stagePriority.creditNoteGenerated:
                          this.refundEmailAttachments[
                            `creditNote[::]${checkMultipleAtch.length - 1}`
                          ] = `Credit Note - ${checkMultipleAtch.length}`;
                          break;

                        case this.hrdcData.stagePriority
                          .autoGeneratedTaxpodInvoice:
                          this.refundEmailAttachments[
                            `autoGeneratedInvoice[::]${
                              checkMultipleAtch.length - 1
                            }`
                          ] = `Client/taxPOD Invoice - ${checkMultipleAtch.length}`;
                          break;
                      }
                    }

                    attachments[stageId] = {
                      fileName: atch.oriFileName,
                    };
                  }
                }

                attachments[stageId].fileUrl = filesArr;
              }
            }

            // Get refund email attachment.
            this.apiGetRefundAttachment.setParams({
              applicationUuid: this.$route.query._ausk,
              attachments,
            });

            this.apiGetRefundAttachment.setCallbackCompleted((response) => {
              try {
                const { status, attn } = response;
                if (status) {
                  for (const item of attn) {
                    // Create a Blob object from the data
                    const blob = new Blob([new Uint8Array(item.dataArray)]);

                    let fileName = null;
                    let file = null;

                    // Cater for other refund email attachment.
                    if (item.other == true) {
                      switch (item.type) {
                        case "refundEmailOtherAttachments":
                          file = new File([blob], item.fileName, {
                            type: `application/${item.extension}`,
                          });

                          this.formData.refundOtherAttachments.push(file);
                          break;
                      }
                      continue;
                    }

                    switch (Number(item.type)) {
                      case this.hrdcData.stagePriority
                        .autoGeneratedTaxpodInvoice:
                        fileName = "Client/taxPOD Invoice";
                        // Create the File object
                        file = new File([blob], fileName, {
                          type: `application/${item.extension}`,
                        });

                        this.formData.autoGeneratedTaxpodInvoice.push(file);
                        break;

                      case this.hrdcData.stagePriority.creditNote:
                      case this.hrdcData.stagePriority.creditNoteGenerated:
                        fileName = "Credit Note";
                        // Create the File object
                        file = new File([blob], fileName, {
                          type: `application/${item.extension}`,
                        });

                        this.formData.creditNote.push(file);
                        break;

                      case this.hrdcData.stagePriority.paymentAdviceFromHrdc:
                        fileName = "Payment Advice From HRDC";
                        // Create the File object
                        file = new File([blob], fileName, {
                          type: `application/${item.extension}`,
                        });

                        this.formData.paymentAdviceFromHrdc = file;
                        break;

                      case this.hrdcData.stagePriority.paymentAdviceFromClient:
                        fileName = "Payment Advice From Client";
                        // Create the File object
                        file = new File([blob], fileName, {
                          type: `application/${item.extension}`,
                        });

                        this.formData.paymentAdviceFromClient = file;
                        break;
                    }
                  }

                  // Only load the email content, after all watch run.
                  this.formData.emailContent = refundEmailTemplate;
                }

                this.loadingDialog = !this.loadingDialog;
              } catch (err) {
                console.log(err);
              }
            });

            this.apiGetRefundAttachment.fetch();
          }
        } catch (err) {
          console.log(err);
        }
      });
    } else {
      this.apiGetRefundEmailRecipientHistory.setCallbackCompleted(
        (response) => {
          const { status, application, company } = response;
          if (status) {
            // Assign taxpod price.
            this.formData.taxpodPrice = application.totalPrice;

            // Check if pricing is unsual, display warning.
            if (
              !this.hrdcData.staticTaxpodPricing.includes(
                Number(application.totalPrice)
              )
            ) {
              this.alertDanger.push("Detected Unusual Price, Please Take Note");
            }

            // Assign subscription data.
            const subscription = application.CompanyTaxpodSubscription.find(
              (item) => item.applicationUuid == this.formData.applicationNo
            );

            // Subscription Period.
            this.formData.subscriptionStartDate =
              subscription !== undefined
                ? subscription.subscriptionStartDate
                : null;
            this.formData.subscriptionEndDate =
              subscription !== undefined
                ? subscription.subscriptionEndDate
                : null;

            // Append Email Subject.
            let emailSubject = null;
            let recipients = null;
            const applicationEmail = application.ApplicationEmail.find((item) =>
              refundStage.includes(item.stageId)
            );

            if (applicationEmail) {
              emailSubject = `${applicationEmail.emailSubject}`;

              recipients = applicationEmail.ApplicationEmailDetail;
            }

            this.formData.emailSubject = emailSubject;

            // Attached authorization/any other attachment if got any.
            if (application.ApplicationAttachment.length > 0) {
              for (const attn of application.ApplicationAttachment) {
                if (attn.oriFileName) {
                  this.otherRefundEmailAttachments[attn.oriFileName] =
                    attn.oriFileName;
                }
              }
            }

            // Assign recipient data.
            if (recipients) {
              for (const recipient of recipients) {
                if (recipient.recipientType == "TO") {
                  this.formData.toEmail.push(recipient.recipientEmail);
                  this.formData.toEmailUuid.push(recipient.uuid);
                }
                if (recipient.recipientType == "CC") {
                  this.formData.ccEmail.push(recipient.recipientEmail);
                  this.formData.ccEmailUuid.push(recipient.uuid);
                }
              }
            }

            // Email Content
            let refundEmailTemplate = application.ApplicationEmail.find(
              (item) => refundStage.includes(item.stageId)
            );

            if (refundEmailTemplate) {
              refundEmailTemplate = refundEmailTemplate.emailContent;
            }

            // Check is drafted refund email template got keyword 'screenshot' or not.
            let ssCheck = false;
            if (refundEmailTemplate.indexOf("Screenshot") !== -1) {
              ssCheck = true;
              this.refundEmailAttachments.ssRefundBankAccDetails =
                "Screenshot Refund Bank Account Details";
            }

            this.formData.ssCheck = ssCheck;

            // Draft for attachment.
            const stagePriorities = [
              this.hrdcData.stagePriority.creditNote,
              this.hrdcData.stagePriority.creditNoteGenerated,
              this.hrdcData.stagePriority.autoGeneratedTaxpodInvoice,
              this.hrdcData.stagePriority.paymentAdviceFromHrdc,
              this.hrdcData.stagePriority.paymentAdviceFromClient,
              this.hrdcData.stagePriority.refundEmailOtherAttachments,
            ];

            const attachments = {};
            let otherRefundAttnCount = 0;

            for (const stageId of stagePriorities) {
              let filesArr = [];
              let checkMultipleAtch = [];
              const attachment = application.ApplicationAttachment.filter(
                (item) => item.stageId == stageId
              );

              if (attachment.length > 0) {
                for (const atch of attachment) {
                  checkMultipleAtch.push(atch.stageId);
                  // Cater for other refund attachments.
                  if (
                    stageId ==
                    this.hrdcData.stagePriority.refundEmailOtherAttachments
                  ) {
                    if (!attachments[stageId]) {
                      attachments[stageId] = {};
                    }

                    attachments[stageId][otherRefundAttnCount] = {
                      fileUrl: atch.fileUrl,
                      fileName: atch.oriFileName,
                    };
                    otherRefundAttnCount++;
                  } else {
                    filesArr.push(atch.fileUrl);

                    if (checkMultipleAtch.length > 1) {
                      // Get attachment display name based on stageId.
                      switch (atch.stageId) {
                        case this.hrdcData.stagePriority.creditNoteGenerated:
                          this.refundEmailAttachments[
                            `creditNote[::]${checkMultipleAtch.length - 1}`
                          ] = `Credit Note - ${checkMultipleAtch.length}`;
                          break;

                        case this.hrdcData.stagePriority
                          .autoGeneratedTaxpodInvoice:
                          this.refundEmailAttachments[
                            `autoGeneratedInvoice[::]${
                              checkMultipleAtch.length - 1
                            }`
                          ] = `Client/taxPOD Invoice - ${checkMultipleAtch.length}`;
                          break;
                      }
                    }

                    attachments[stageId] = {
                      fileName: atch.oriFileName,
                    };
                  }
                }

                attachments[stageId].fileUrl = filesArr;
              }
            }

            // Get refund email attachment.
            this.apiGetRefundAttachment.setParams({
              applicationUuid: this.$route.query._ausk,
              attachments,
            });

            this.apiGetRefundAttachment.setCallbackCompleted((response) => {
              try {
                const { status, attn } = response;
                if (status) {
                  for (const item of attn) {
                    // Create a Blob object from the data
                    const blob = new Blob([new Uint8Array(item.dataArray)]);

                    let fileName = null;
                    let file = null;

                    // Cater for other refund email attachment.
                    if (item.other == true) {
                      switch (item.type) {
                        case "refundEmailOtherAttachments":
                          file = new File([blob], item.fileName, {
                            type: `application/${item.extension}`,
                          });

                          this.formData.refundOtherAttachments.push(file);
                          break;
                      }
                      continue;
                    }

                    switch (Number(item.type)) {
                      case this.hrdcData.stagePriority
                        .autoGeneratedTaxpodInvoice:
                        fileName = "Client/taxPOD Invoice";
                        // Create the File object
                        file = new File([blob], fileName, {
                          type: `application/${item.extension}`,
                        });

                        this.formData.autoGeneratedTaxpodInvoice.push(file);
                        break;

                      case this.hrdcData.stagePriority.creditNote:
                      case this.hrdcData.stagePriority.creditNoteGenerated:
                        fileName = "Credit Note";
                        // Create the File object
                        file = new File([blob], fileName, {
                          type: `application/${item.extension}`,
                        });

                        this.formData.creditNote.push(file);
                        break;

                      case this.hrdcData.stagePriority.paymentAdviceFromHrdc:
                        fileName = "Payment Advice From HRDC";
                        // Create the File object
                        file = new File([blob], fileName, {
                          type: `application/${item.extension}`,
                        });

                        this.formData.paymentAdviceFromHrdc = file;
                        break;

                      case this.hrdcData.stagePriority.paymentAdviceFromClient:
                        fileName = "Payment Advice From Client";
                        // Create the File object
                        file = new File([blob], fileName, {
                          type: `application/${item.extension}`,
                        });

                        this.formData.paymentAdviceFromClient = file;
                        break;
                    }
                  }

                  // Only load the email content, after all watch run.
                  this.formData.emailContent = refundEmailTemplate;
                }

                this.loadingDialog = !this.loadingDialog;
              } catch (err) {
                console.log(err);
              }
            });

            this.apiGetRefundAttachment.fetch();

            // HRDC Invoice No.
            this.formData.manualInvoiceToHrdc = application.manualInvoiceToHrdc;

            // Client/taxPOD Invoice No.
            this.formData.autoGeneratedTaxpodInvoiceNo =
              application.autoGeneratedTaxpodInvoiceNo;

            // CN No.
            this.formData.creditNoteNo = application.creditNoteNo;

            // Payment Advice Type.
            this.formData.paymentAdviceType =
              application.paymentAdviceFromClientType;

            // Assign company data.
            this.formData.oldCompanyName = this.capitalizeFirstLetter(
              company.oldCompanyName
            );
            this.formData.oldPicName = company.oldPicName;
            this.formData.oldSsmNo = company.oldSsmNo;
            this.formData.bankUuid = company.bankUuid;
            this.formData.oldBankAccount = company.oldBankAccount;

            let paymentReceivedDate = application.ApplicationLog.find(
              (item) =>
                item.stageId ==
                this.hrdcData.stagePriority.paymentReceivedFromHrdc
            );

            if (paymentReceivedDate) {
              this.formData.paymentReceivedDate = moment(
                paymentReceivedDate.startDate,
                "YYYY-MM-DD HH:mm:ssZ"
              ).format("D MMMM YYYY");
            }

            // Assign refund remarks.
            this.formData.refundRemarks = application.refundRemarks;

            // Assign refund email company bank details.
            this.updateEmailContent();
          }
        }
      );

      this.apiGetRefundEmailRecipientHistory.setParams({
        applicationUuid: this.$route.query._ausk,
        stageId: this.hrdcData.stagePriority.refundEmailSubmission,
      });
      this.apiGetRefundEmailRecipientHistory.fetch();
      // this.loadingDialog = !this.loadingDialog;
    }
  },
  mounted() {},
  methods: {
    clearErrorMsg(field = null) {
      if (!field) {
        for (let error in this.errorField) {
          this.errorField[error] = null;
        }
      } else {
        this.errorField[field] = null;
      }
    },
    showErrorMessage(response) {
      for (const key in response.data) {
        this.errorField[key] = null;
        this.errorField[key] = response.data[key];
      }
    },
    getEmailContent() {
      this.loadingDialog = !this.loadingDialog;
      this.emailModalDetails.emailModalHeader = "Refund Email";
      this.apiGetEmailDetails.setParams({
        applicationUuid: this.formData.applicationNo,
        stageId: this.hrdcData.stagePriority.refundEmailSubmission,
      });
      this.apiGetEmailDetails.fetch();
      this.apiGetEmailDetails.setCallbackCompleted((response) => {
        if (response.status) {
          const applicationEmail = response.data;
          const applicationEmailDetails =
            applicationEmail.ApplicationEmailDetail;

          const toRecipients = [];
          const ccRecipients = [];
          this.emailModalDetails.emailModalSubject =
            applicationEmail.emailSubject;
          this.emailModalDetails.emailModalContent =
            applicationEmail.emailContent;
          for (const emailDetail of applicationEmailDetails) {
            if (emailDetail.recipientType == "TO") {
              toRecipients.push(emailDetail.recipientEmail);
            }
            if (emailDetail.recipientType == "CC") {
              ccRecipients.push(emailDetail.recipientEmail);
            }
          }

          this.emailModalDetails.emailModalToRecipient = toRecipients;
          this.emailModalDetails.emailModalCcRecipient = ccRecipients;
        }
      });
      this.loadingDialog = !this.loadingDialog;
    },
    async previewOrDownloadAttachment(fileUrl, action) {
      this.loadingDialog = !this.loadingDialog;

      // Get the stageId for each file.
      const stageId = this.getStageIdFromFile(fileUrl);

      // Check if got specific delimeter to handle for multiple attachments.
      let multipleAttachmentIndex = 0;
      if (fileUrl.includes("[::]")) {
        multipleAttachmentIndex = fileUrl.split("[::]")[1];
      }

      this.apiGetFileAttachment.setParams({
        applicationUuid: this.formData.applicationNo,
        stageId,
      });

      // Get the file blob.
      this.apiGetFileAttachment.setCallbackCompleted(async (response) => {
        try {
          if (response.status) {
            const data = response.data;
            const applicationAttachment = data.ApplicationAttachment;

            if (applicationAttachment.length > 0) {
              const oriFileBlob =
                applicationAttachment[multipleAttachmentIndex].fileUrl;
              const attachment = oriFileBlob.split(".");
              const attachmentPathWithoutExt = attachment[0];
              const attachmentExt = attachment[1].toLowerCase();
              const fileBlob = `${attachmentPathWithoutExt}.${attachmentExt}`;

              let responseType = "blob";
              let isImage = false;
              let blobType = `application/${attachmentExt}`;
              let streamUrl = this.apiDownloadOrPreviewAttachment.url;
              if (this.isImage(fileBlob)) {
                isImage = true;
                blobType = `image/${attachmentExt}`;
              }

              await this.$axios
                .get(streamUrl, {
                  responseType,
                  headers: {
                    "Content-Type": "application/json",
                  },
                  params: {
                    filePath: fileBlob,
                    isImage,
                  },
                })
                .then((response) => {
                  if (action == "preview") {
                    let reader = new FileReader();
                    reader.onload = (event) => {
                      // Set base64 string as image source
                      let originalDataUrl = event.target.result;
                      let modifiedDataUrl = originalDataUrl.replace(
                        /^data:application\/octet-stream/,
                        `data:${blobType}`
                      );
                      this.imageUrl = `${modifiedDataUrl}`;
                    };
                    reader.readAsDataURL(response.data);
                  }
                })
                .catch((error) => {
                  if ((error.response.status == 404) | true) {
                    this.$store.dispatch("showMessage", {
                      message:
                        "Failed to Preview/Download attachment, kindly contact administrator",
                      messageType: "error",
                      timeout: 2000,
                    });
                  }
                  this.loadingDialog = !this.loadingDialog;
                });
            } else {
              this.$store.dispatch("showMessage", {
                message: "Attachment not found!",
                messageType: "error",
                timeout: 2000,
              });
              this.loadingDialog = !this.loadingDialog;
            }
          }
          this.loadingDialog = !this.loadingDialog;
        } catch (err) {
          this.$store.dispatch("showMessage", {
            message:
              "Failed to Preview/Download attachment, kindly contact administrator",
            messageType: "error",
            timeout: 2000,
          });
          this.loadingDialog = !this.loadingDialog;
        }
      });
      this.apiGetFileAttachment.fetch();
    },
    isImage(url) {
      return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
    },
    checkAttachment(e, field) {
      if (e) {
        this.clearErrorMsg(field);
      }
    },
    getStageIdFromFile(file) {
      let stage;

      if (file.includes("creditNote")) {
        return this.hrdcData.stagePriority.creditNote;
      }

      if (file.includes("autoGeneratedInvoice")) {
        return this.hrdcData.stagePriority.autoGeneratedTaxpodInvoice;
      }

      switch (file) {
        case "autoGeneratedInvoice":
          stage = this.hrdcData.stagePriority.autoGeneratedTaxpodInvoice;
          break;
        case "creditNote":
          stage = this.hrdcData.stagePriority.creditNote;
          break;
        case "creditNoteGenerated":
          stage = this.hrdcData.stagePriority.creditNoteGenerated;
          break;
        case "ssRefundBankAccDetails":
          stage = this.hrdcData.stagePriority.applicationSubmitted;
          break;
        case "manualInvoiceToHrdc":
          stage = this.hrdcData.stagePriority.manualInvoiceToHrdc;
          break;
        case "paymentAdviceFromClient":
          stage = this.hrdcData.stagePriority.paymentAdviceFromClient;
          break;
        case "paymentAdviceFromHrdc":
          stage = this.hrdcData.stagePriority.paymentAdviceFromHrdc;
          break;
        default:
          stage = file;
          break;
      }

      return stage;
    },
    capitalizeFirstLetter(str) {
      return str
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    },
    updateEmailContent() {
      this.formData.emailContent = `
        <span>Hi AP & AR Team,</span><br /><br />
        <span>Grateful if you could help us process a
          <b> ${this.formattedPrice} </b>
          refund for ${this.formData.oldCompanyName} under YYC taxPOD.
        </span>
        <br />
        <br />
        <span>Client has bought YYC taxPOD for the period
          <b>${moment(this.formData.subscriptionStartDate, "YYYY-MM-DD").format(
            "D MMMM YYYY"
          )}</b>
            to
          <b>${moment(this.formData.subscriptionEndDate, "YYYY-MM-DD").format(
            "D MMMM YYYY"
          )}</b>
            with invoice
          <b>${
            this.formData.autoGeneratedTaxpodInvoiceNo == null
              ? "_________"
              : this.formData.autoGeneratedTaxpodInvoiceNo
          }</b>. After that, client decided to
          claim HRDC for YYC taxPOD. We have received the claim from HRDC on
          <b>${this.formData.paymentReceivedDate}</b>
            <b>(Invoice No: ${this.formData.manualInvoiceToHrdc})</b>.
        </span>
        <br />

        <span>
          Hence, we need to refund the HRDC claim to client. We have obtained
          refund approval from Chau.
        </span>
        <br />
        <span>Attached please find the following documents:</span><br /><br />

        <span>1.
          <b>YYC taxPOD Invoice - ${
            this.formData.autoGeneratedTaxpodInvoiceNo == null
              ? "_________"
              : this.formData.autoGeneratedTaxpodInvoiceNo
          }</b></span><br /><br
        />

        <span>2.
        <b>Invoice billed to HRDC -
          ${this.formData.manualInvoiceToHrdc}</b></span><br /><br />

        <span>3. <b>Payment Advice from Client - ${
          this.formData.paymentAdviceType
        }</b></span><br /><br />

        <span>4.
        <b>Payment Advice from HRDC -
          ${this.formData.manualInvoiceToHrdc}</b></span><br /><br />

        <span>5. <b>Credit Note - ${
          this.formData.creditNoteNo == null
            ? "__________"
            : this.formData.creditNoteNo
        }</b></span><br /><br />

        <span>6.
          <b>${this.numberSixItemWord}</b></span><br
        /><br />

        <span>7. <b>Refund Bank Account Details:</b></span><br />

        <span>
          Company Name: ${
            this.formData.oldBankAccountHolderName
          }<br>Company Registration No.: ${this.formData.oldSsmNo}<br>Bank: ${
        this.formData.bankUuid
      }<br>Bank Account Number: ${this.formData.oldBankAccount}
        </span>
      `;
    },
    //EOC
    modalRefundEmailApproval2Submit() {
      this.refundEmailApproval2SubmitDialog =
        !this.refundEmailApproval2SubmitDialog;
      this.submit();
    },
    redirectBack() {
      let route = "HrdcApplication";
      if (this.params.backTo) {
        route = this.params.backTo;
      }

      this.$store.commit("assignDeveloperData", {
        paginationPageNo: this.$route.query.currentPageNo,
      });

      this.$router.push({
        name: route,
        params: {
          modelKey: this.model.key,
          backTo: "",
        },
        query: {
          viewId: this.$store.state.hrdc.data.viewId,
          _ausk: this.$route.query._ausk,
        },
      });
    },
    addDropFile(e, key, fileType, matchExtType) {
      let file = e.dataTransfer.files[0];

      if (matchExtType) {
        if (!file.type.match(matchExtType)) {
          switch (matchExtType) {
            case "pdf.*":
              this.errorField[key] = "Please select pdf file format";
              break;
            case "image/*":
              this.errorField[key] = "Please select only image";
              break;
          }
          return;
        }
      }

      this.formData[key] = file;
      if (fileType) {
        this.scanPdf(e, key, fileType);
      }

      if (e) {
        this.clearErrorMsg(key);
      }
    },
    addDropMultipleFile(e, key, fileType, matchExtType) {
      let files = e.dataTransfer.files;

      for (const file of files) {
        if (matchExtType) {
          if (!file.type.match(matchExtType)) {
            switch (matchExtType) {
              case "pdf.*":
                this.errorField[key] = "Please select pdf file format";
                break;
              case "image/*":
                this.errorField[key] = "Please select only image";
                break;
            }
            return;
          }
        }

        this.formData[key].push(file);

        if (fileType) {
          this.scanPdf(e, key, fileType);
        }
      }

      if (e) {
        this.clearErrorMsg(key);
      }
    },
    scanPdf($event, fileType, displayFileInvoiceNo) {
      this.clearErrorMsg(fileType);
      this.clearErrorMsg(displayFileInvoiceNo);
      if ($event) {
        this.loadingDialog = !this.loadingDialog;
        const fd = new FormData();

        let counter = 0;
        for (const file of this.formData[fileType]) {
          fd.append(`${fileType}_${counter}`, file);
          counter++;
        }

        fd.append("fileType", fileType);
        fd.append("counter", counter);

        this.apiScanPdfText.setParams(fd);
        this.apiScanPdfText.setCallbackCompleted((response) => {
          try {
            const { data } = response;
            if (response.status) {
              this.formData[displayFileInvoiceNo] = data;
            } else {
              this.formData[displayFileInvoiceNo] = null;
              this.$store.dispatch("showMessage", {
                message: data,
                messageType: "error",
                timeout: 2000,
              });
            }
            this.loadingDialog = !this.loadingDialog;
          } catch (err) {
            console.log(err);
          }
        });
        this.apiScanPdfText.fetch();
      } else {
        // Empty the invoice no. if remove attachment.
        this.formData[displayFileInvoiceNo] = null;
      }
    },
    getFileExt(fileType) {
      if (fileType) {
        // Get the file extension type.
        const fileSplit = fileType.split("/");
        return fileSplit[1];
      }

      return fileType;
    },
    async submit() {
      const changeFileExtensions = ["paymentAdviceFromClient"];

      for (const file of changeFileExtensions) {
        if (this.formData[file]) {
          let oriFileExt = await this.getOriginalAttachmentFileExtension(
            this.formData[file]
          );

          this.formData[file] = new File(
            [this.formData[file]],
            this.formData[file].name,
            {
              type: oriFileExt,
            }
          );
        }
      }

      this.loadingDialog = !this.loadingDialog;

      const fd = new FormData();
      fd.append("applicationUuid", this.$route.query._ausk);
      fd.append("totalPrice", this.formData.taxpodPrice);
      fd.append("subscriptionStartDate", this.formData.subscriptionStartDate);
      fd.append("subscriptionEndDate", this.formData.subscriptionEndDate);
      fd.append(
        "refundEmailApproval2Date",
        this.formData.refundEmailApproval2Date
      );
      fd.append("emailSubject", this.formData.emailSubject);
      fd.append("toEmail", this.formData.toEmail);
      fd.append("ccEmail", this.formData.ccEmail);
      fd.append("toEmailUuid", this.formData.toEmailUuid);
      fd.append("ccEmailUuid", this.formData.ccEmailUuid);

      // Loop taxPOD invoice.
      let taxpodInvoiceCounter = 0;
      for (const taxpodInvoice of this.formData.autoGeneratedTaxpodInvoice) {
        fd.append(
          `autoGeneratedTaxpodInvoice_${taxpodInvoiceCounter}`,
          taxpodInvoice
        );
        taxpodInvoiceCounter++;
      }
      fd.append("taxpodInvoiceCounter", taxpodInvoiceCounter);

      fd.append(
        "autoGeneratedTaxpodInvoiceNo",
        this.formData.autoGeneratedTaxpodInvoiceNo
      );

      let cnCounter = 0;
      for (const cn of this.formData.creditNote) {
        fd.append(`creditNote_${cnCounter}`, cn);
        cnCounter++;
      }

      fd.append("cnCounter", cnCounter);
      fd.append("creditNoteNo", this.formData.creditNoteNo);
      // fd.append("creditNoteFileExt", this.getFileExt(creditNoteFileType));
      fd.append(
        "paymentAdviceFromClient",
        this.formData.paymentAdviceFromClient
      );
      fd.append("paymentAdviceFromHrdc", this.formData.paymentAdviceFromHrdc);
      fd.append("paymentAdviceType", this.formData.paymentAdviceType);
      fd.append("referById", this.auth.uuid);
      fd.append("referByName", this.auth.name);
      fd.append("referByEmail", this.auth.email);
      fd.append("referByMobile", this.auth.mobile ?? "+6016-992 2203");
      fd.append("editMode", this.editMode);
      fd.append("refundRemarks", this.formData.refundRemarks);
      fd.append("emailContent", this.formData.emailContent);
      fd.append("ssCheck", this.formData.ssCheck);
      fd.append("companyName", this.formData.oldCompanyName);
      fd.append("companyPicName", this.formData.oldPicName);

      // Assign multiple attachments to Form Data.
      for (let i = 0; i < this.formData.refundOtherAttachments.length; i++) {
        fd.append(
          `refundOtherAttachments_${i}`,
          this.formData.refundOtherAttachments[i]
        );
      }

      this.apiSubmit.setCallbackCompleted((response) => {
        try {
          this.clearErrorMsg();
          this.loadingDialog = !this.loadingDialog;
          if (!response.status) {
            this.showErrorMessage(response);
            if (response.message) {
              this.$store.dispatch("showMessage", {
                message: response.message,
                messageType: "error",
                timeout: 2000,
              });
            }
          }

          if (response.status) {
            this.$store.commit("assignDeveloperData", {
              paginationPageNo: this.$route.query.currentPageNo,
            });

            this.$router.push({
              name: "HrdcApplication",
              query: {
                viewId: this.$store.state.hrdc.data.viewId,
              },
            });
            this.$store.dispatch("showMessage", {
              message: response.data,
              messageType: "success",
              timeout: 2000,
            });
          }
        } catch (err) {
          console.log(err);
        }
      });

      this.apiSubmit.setParams(fd);
      this.apiSubmit.fetch();
    },
    modalRefundEmailReject() {
      this.loadingDialog = !this.loadingDialog;

      this.apiReject.setParams({
        applicationUuid: this.$route.query._ausk,
        userUuid: this.auth.uuid,
        rejectToStage: this.hrdcData.stagePriority.refundEmailSubmission,
        rejectStageId: this.hrdcData.stagePriority.refundEmailApproval2Rejected,
        remarks: this.formData.refundRemarks,
      });

      this.apiReject.setCallbackCompleted((response) => {
        try {
          this.loadingDialog = !this.loadingDialog;
          if (response.status) {
            this.$store.dispatch("showMessage", {
              message: response.data,
              messageType: "success",
              timeout: 2000,
            });

            this.$router.push({
              name: "HrdcApplication",
              query: {
                viewId: this.$store.state.hrdc.data.viewId,
              },
            });
          }
        } catch (err) {
          this.loadingDialog = !this.loadingDialog;
          console.log(err);
        }
      });

      this.apiReject.fetch();

      this.refundEmailRejectDialog = false;
    },
  },
};
</script>
