export default {
  methods: {
    // Check if form already filled.
    checkFormAlreadyFilledGte(currentStageId, maxStageId, errorMsg) {
      this.checkFormAlreadyFilled(currentStageId, maxStageId, true, errorMsg);
    },

    checkFormAlreadyFilledGreater(currentStageId, maxStageId, errorMsg) {
      this.checkFormAlreadyFilled(currentStageId, maxStageId, false, errorMsg);
    },

    checkFormAlreadyFilled(
      currentStageId,
      maxStageId,
      inclusive,
      errorMessage = null
    ) {
      if (
        (inclusive && currentStageId >= maxStageId) ||
        (!inclusive && currentStageId > maxStageId)
      ) {
        let errorMsg = "Please be informed that this form already filled in!";
        if (errorMessage) {
          errorMsg = errorMessage;
        }
        this.alertError.push(errorMsg);
        this.editMode = true;
      }
    },

    // Check if form haven't filled.
    checkFormNotYetFilledLesser(
      currentStageId,
      maxStageId,
      errorMessage = null
    ) {
      if (currentStageId < maxStageId) {
        let errorMsg = "Please complete previous step";
        if (errorMessage) {
          errorMsg = errorMessage;
        }
        this.alertError.push(errorMsg);
        this.btnDisabled = true;
      }
    },
  },
};
